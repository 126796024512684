import { useState, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useAccountLogout } from './useAccountLogout';
import { notifyError, handleErrorResponse } from 'helper/utils';

export function usePostData(apiEndpoint) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const {user} = useSelector(state => state.auth);
  const authToken = user && user.token;
  const logout = useAccountLogout();


  const postData = useCallback(async (postData) => {
    setLoading(true);
    setError(null);

    try {

      if (authToken !== undefined || authToken !== null) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
      }
      
      const responseData = await axios.post(BASE_URL+apiEndpoint, postData);
      setResponse(responseData.data);
      // Optionally, you can handle success or further actions here.
    } catch (error) {
      handleErrorResponse(error, setError, logout);
    } finally {
      setLoading(false);
    }
  }, [authToken, apiEndpoint]);

  return { postData, loading, error, response };
}
