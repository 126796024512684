// import node module libraries
import React, { Fragment, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
    Col,
    Row,
    Container,
    Card,
    Spinner,
} from 'react-bootstrap';

// import custom components
import PageHeading from 'components/marketing/common/page-headings/PageHeading';
import { usePostData } from 'hooks/usePostData';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import Lottie from 'react-lottie';

// Import Lottie animation JSON files
import successAnimation from '../../../../../assets/json/payment-success.json';
import failureAnimation from '../../../../../assets/json/payment-failed.json';

const PaystackVerification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { paystackData } = location.state || {}

    // const { loading, postData, response } = usePostData('/payment/make');
    const { loading: loadVerify, postData: postVerify, response: responseVerify } = usePostData('/payment/callback');

    // Lottie options for success and failure animations
    const successOptions = {
        loop: true,
        autoplay: true,
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    
    const failureOptions = {
        loop: true,
        autoplay: true,
        animationData: failureAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        // send paypal payment verification
        const verifyPayment = async () => {
            // send request to server to make payment
            postVerify(paystackData)
        }
        verifyPayment();
    }, []);

    return (
        <Fragment>
            {/* Page header */}
            <PageHeading pagetitle="PayStack Payment Verification Center" />
            {/*  Content */}
            <section className="py-6 bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} sm={10}>
                            <Card className="shadow-sm">
                                <Card.Body className="text-center" style={{height: "70vh"}}>
                                    {loadVerify ? (
                                        <div>
                                            <Spinner animation="border" variant="primary" className="mb-3" />
                                            <p>Verifying Payment, please wait...</p>
                                        </div>
                                    ) : (
                                        <div>
                                            {responseVerify && responseVerify?.status === "Success" ? (
                                                <div>
                                                    <CheckCircleFill className="text-success display-1 mb-3" />
                                                    <h4 className="text-success">Payment Successful</h4>
                                                    <p>Your payment has been verified successfully.</p>
                                                    <Lottie options={successOptions} height={300} width={300} />
                                                    <Link to={"/"}>Go to Home</Link>
                                                </div>
                                            ) : (
                                                <div>
                                                    <XCircleFill className="text-danger display-1 mb-3" />
                                                    <h4 className="text-danger">Payment Failed</h4>
                                                    <p>Unfortunately, we couldn't verify your payment. Please try again.</p>
                                                    <Lottie options={failureOptions} height={300} width={300} />
                                                    <Link to={"/"}>Go to Home</Link>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default PaystackVerification;
